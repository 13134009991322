<template>
  <div>
    <my-nav-bar
      title="数据总览"
      left-text="返回"
      left-arrow
    />
    <div class="filter">
      <van-cell center title="门店" :value="currentStore" is-link @click="showActionSheet = true" />
      <van-cell center title="类型" :value="dateTypeName" is-link @click="showDateTypeActionSheet = true" />
      <van-cell center title="日期" :value="listQuery.date" is-link @click="showDatePicker = true" />
    </div>
    <div v-if="totalData" class="data">
      <van-cell-group class="data-block" :border="false" title="配送数据">
        <van-cell title="订单数量" :value="`${totalData.delivery_count}张`" />
        <van-cell class="amount" title="订单金额" :value="`￥${totalData.delivery_order_amount}`" center />
        <van-cell v-if="totalData.delivery_red_packet_amount < 0" class="amount" title="扣减红包" :value="`￥${totalData.delivery_red_packet_amount}`" center />
        <van-cell v-if="totalData.delivery_coupon_amount < 0" class="amount" title="扣减优惠券" :value="`￥${totalData.delivery_coupon_amount}`" center />
        <van-cell v-if="totalData.delivery_after_amount < 0" class="amount" title="扣减退货" :value="`￥${totalData.delivery_after_amount}`" center />
        <van-cell class="amount" title="应收" :value="`￥${totalData.delivery_sales_amount}`" center />
        <van-cell v-if="totalData.delivery_unpaid_amount < 0" class="amount" title="扣减签单" :value="`￥${totalData.delivery_unpaid_amount}`" center />
        <van-cell v-if="totalData.delivery_decimal_amount < 0" class="amount" title="扣减尾数" :value="`￥${totalData.delivery_decimal_amount}`" center />
        <van-cell class="amount" title="定金" :value="`￥${totalData.delivery_deposit_amount}`" center />
        <van-cell class="amount" title="实收(含定金)" :value="`￥${totalData.delivery_real_amount}`" center />
      </van-cell-group>
      <van-cell-group v-if="totalData.delivery_unpaid_amount < 0" class="data-block" :border="false" title="配送签单">
        <van-cell v-if="totalData.delivery_unpaid_after_amount < 0" class="amount" title="签单退货" :value="`￥${totalData.delivery_unpaid_after_amount}`" center />
        <van-cell class="amount" title="实际签单" :value="`￥${totalData.delivery_real_unpaid_amount}`" center />
      </van-cell-group>
      <van-cell-group class="data-block" title="门店数据">
        <van-cell title="订单数量" :value="`${totalData.store_count}张`" />
        <van-cell class="amount" title="订单金额" :value="`￥${totalData.store_order_amount}`" center />
        <van-cell v-if="totalData.store_red_packet_amount < 0" class="amount" title="扣减红包" :value="`￥${totalData.store_red_packet_amount}`" center />
        <van-cell v-if="totalData.store_coupon_amount < 0" class="amount" title="扣减优惠券" :value="`￥${totalData.store_coupon_amount}`" center />
        <van-cell v-if="totalData.store_after_amount < 0" class="amount" title="扣减退货" :value="`￥${totalData.store_after_amount}`" center />
        <van-cell class="amount" title="应收" :value="`￥${totalData.store_sales_amount}`" center />
        <van-cell v-if="totalData.store_unpaid_amount < 0" class="amount" title="扣减签单" :value="`￥${totalData.store_unpaid_amount}`" center />
        <van-cell v-if="totalData.store_decimal_amount < 0" class="amount" title="扣减尾数" :value="`￥${totalData.store_decimal_amount}`" center />
        <van-cell class="amount" title="定金" :value="`￥${totalData.store_deposit_amount}`" center />
        <van-cell class="amount" title="实收(含定金)" :value="`￥${totalData.store_real_amount}`" center />
      </van-cell-group>
      <van-cell-group v-if="totalData.store_unpaid_amount < 0" class="data-block" :border="false" title="门店签单">
        <van-cell v-if="totalData.store_unpaid_after_amount < 0" class="amount" title="签单退货" :value="`￥${totalData.store_unpaid_after_amount}`" center />
        <van-cell class="amount" title="实际签单" :value="`￥${totalData.store_real_unpaid_amount}`" center />
      </van-cell-group>
      <van-cell-group title="差异数据">
        <van-cell class="amount" title="退货差价" :value="`￥${totalData.after_diff_amount}`" center />
      </van-cell-group>
      <van-cell-group title="门店营业">
        <van-cell class="amount" title="总订单金额" :value="`￥${totalData.total_order_amount}`" center />
        <van-cell v-if="totalData.total_red_packet_amount < 0" class="amount" title="总扣减红包" :value="`￥${totalData.total_red_packet_amount}`" center />
        <van-cell v-if="totalData.total_coupon_amount < 0" class="amount" title="总扣减优惠券" :value="`￥${totalData.total_coupon_amount}`" center />
        <van-cell v-if="totalData.total_after_amount < 0" class="amount" title="总扣减退货" :value="`￥${totalData.total_after_amount}`" center />
        <van-cell class="amount" title="总应收" :value="`￥${totalData.total_sales_amount}`" center />
        <van-cell v-if="totalData.total_unpaid_amount < 0" class="amount" title="总扣减签单" :value="`￥${totalData.total_unpaid_amount}`" center />
        <van-cell v-if="totalData.total_decimal_amount < 0" class="amount" title="总扣减尾数" :value="`￥${totalData.total_decimal_amount}`" center />
        <van-cell class="amount" title="总定金" :value="`￥${totalData.total_deposit_amount}`" center />
        <van-cell class="amount" title="总实收(含定金)" :value="`￥${totalData.total_real_amount}`" center />
        <van-cell class="amount" title="总成本" :value="`￥${totalData.total_cost_amount}`" center />
        <van-cell class="amount" title="总税费" :value="`￥${totalData.total_tax_fee}`" center />
        <van-cell class="amount" title="来货运费" :value="`￥${totalData.total_trans_expenses}`" center />
        <van-cell class="amount" :value="`￥${totalData.gross_profit}`" center>
          <template slot="title">
            <span>毛利</span>
            <span class="sub-title">(总应收 - 总成本 - 总税费 - 来货运费)</span>
          </template>
        </van-cell>
        <van-cell class="amount" title="毛利率" :value="`${totalData.gross_profit_rate}%`" center />
      </van-cell-group>
      <van-cell-group v-if="totalData.total_unpaid_amount < 0" title="签单数据">
        <van-cell v-if="totalData.total_unpaid_after_amount < 0" class="amount" title="总签单退货" :value="`￥${totalData.total_unpaid_after_amount}`" center />
        <van-cell class="amount" title="总实际签单" :value="`￥${totalData.total_real_unpaid_amount}`" center />
      </van-cell-group>
      <van-cell-group title="其它收款">
        <van-cell class="amount" title="签单收款" :value="`￥${totalData.unpaid_receive_amount}`" center is-link @click="toUnpaidReceiveView" />
        <van-cell class="amount" title="欠条收款" :value="`￥${totalData.iou_receive_amount}`" center />
      </van-cell-group>
      <van-cell-group title="门店支出">
        <van-cell class="amount" title="来货入库" :value="`￥${totalData.accept_order_amount}`" is-link center @click="toAcceptOrderView" />
        <van-cell class="amount" title="门店调货" :value="`￥${totalData.trans_order_amount}`" is-link center @click="clickStoreTransfer" />
        <van-cell class="amount" title="费用报销" :value="`￥${totalData.expense_amount}`" center />
        <van-cell class="amount" title="日常开支" :value="`￥${totalData.expense_daily_amount}`" center />
      </van-cell-group>
      <van-cell-group title="联盟商家（门店营业已包含此数据）">
        <van-cell class="amount" title="订单金额" :value="`￥${totalData.business_amount}`" center />
        <van-cell class="amount" title="退货金额" :value="`￥${totalData.business_return_amount}`" center />
        <van-cell class="amount" title="成本金额" :value="`￥${totalData.business_cost_amount}`" center />
        <van-cell class="amount" title="税费" :value="`￥${totalData.business_tax_fee}`" center />
        <van-cell class="amount" title="毛利" :value="`￥${totalData.business_gross_profit}`" center />
        <van-cell class="amount" title="毛利率" :value="`${totalData.business_gross_profit_rate}%`" center />
      </van-cell-group>
      <van-cell-group title="特惠购（门店营业已包含此数据）">
        <van-cell class="amount" title="订单金额" :value="`￥${totalData.pss_amount}`" center />
        <van-cell class="amount" title="成本金额" :value="`￥${totalData.pss_cost_amount}`" center />
        <van-cell class="amount" title="提成金额" :value="`￥${totalData.pss_commission_amount}`" center />
        <van-cell class="amount" title="毛利" :value="`￥${totalData.pss_gross_profit}`" center />
        <van-cell class="amount" title="毛利率" :value="`${totalData.pss_gross_profit_rate}%`" center />
      </van-cell-group>
      <van-cell-group v-if="totalData.chart_data" title="销售额走势图">
        <div id="mychart" />
      </van-cell-group>
    </div>
    <van-action-sheet v-model="showActionSheet" :actions="actions" @select="selectStore" />
    <van-action-sheet v-model="showDateTypeActionSheet" :actions="dateTypeActions" @select="selectDateType" />
    <van-popup v-model="showDatePicker" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择日期"
        @cancel="showDatePicker = false"
        @confirm="selectDate"
      />
    </van-popup>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { currentDate, toDateString, toDateStringMonth, toDateStringYear } from '@/utils'
import { getTotalData } from '@/api/statistics'
import { getStores } from '@/api/home'
import * as Echarts from 'echarts'

export default {
  name: 'TotalData',
  components: { myNavBar },
  data() {
    return {
      currentDate: new Date(),
      currentStore: '全部',
      dateTypeName: '按日统计',
      showDateTypeActionSheet: false,
      dateTypeActions: [
        { name: '按日统计', value: 1 },
        { name: '按月统计', value: 2 },
        { name: '按年统计', value: 3 }
      ],
      listQuery: {
        date: currentDate(),
        store_id: 0,
        date_type: 1
      },
      showDatePicker: false,
      showActionSheet: false,
      actions: [
        { name: '全部', store_id: 0 }
      ],
      totalData: null
    }
  },
  created() {
    this.getData()
    this.$store.commit('setKeepAlive', this.$options.name)

    getStores().then(res => {
      res.data.map(store => {
        this.actions.push({
          store_id: store.id,
          name: store.name
        })
      })
    })
  },
  beforeRouteLeave(to, from, next) {
    // 清除缓存
    if (to.path === '/home') {
      this.$store.commit('rmKeepAlive', this.$options.name)
    }
    next()
  },
  methods: {
    initEcharts(xdata, ydata) {
      const mychart = Echarts.init(document.getElementById('mychart'))
      const options = {
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: xdata
        },
        yAxis: {
          type: 'value'
        },
        grid: {
          left: '15%',
          right: '5%'
        },
        series: [
          {
            data: ydata,
            type: 'line'
          }
        ]
      }
      mychart.setOption(options)
    },
    selectDate(date) {
      this.listQuery.date = toDateString(date)
      this.showDatePicker = false
      this.getData()
    },
    selectStore(action) {
      this.showActionSheet = false
      this.listQuery.store_id = action.store_id
      this.currentStore = action.name
      this.getData()
    },
    selectDateType(action) {
      this.showDateTypeActionSheet = false
      this.listQuery.date_type = action.value
      this.dateTypeName = action.name
      this.getData()
    },
    getData() {
      this.beginLoad()
      getTotalData(this.listQuery).then(res => {
        this.totalData = res.data
        this.endLoad()
        this.$nextTick(() => {
          if (this.totalData.chart_data) {
            this.initEcharts(this.totalData.chart_data.xdata, this.totalData.chart_data.ydata)
          }
        })
      })
    },
    toAcceptOrderView() {
      let time = ''
      let date_type = ''
      switch (this.dateTypeName) {
        case '按日统计':
          time = toDateString(this.currentDate)
          date_type = 'day'
          break
        case '按月统计':
          time = toDateStringMonth(this.currentDate)
          date_type = 'month'
          break
        case '按年统计':
          time = toDateStringYear(this.currentDate)
          date_type = 'year'
          break
      }
      // this.$router.push({ path: '/purchase-accept', query: { time, type: date_type, store_id: this.listQuery.store_id }})
      this.$router.push({
        path: '/total-data-accept',
        query: {
          time, type: date_type,
          store_id: this.listQuery.store_id
        }
      })
    },
    clickStoreTransfer() {
      let time = ''
      let date_type = ''
      switch (this.dateTypeName) {
        case '按日统计':
          time = toDateString(this.currentDate)
          date_type = 'day'
          break
        case '按月统计':
          time = toDateStringMonth(this.currentDate)
          date_type = 'month'
          break
        case '按年统计':
          time = toDateStringYear(this.currentDate)
          date_type = 'year'
          break
      }
      this.$router.push({
        path: '/total-data-trans',
        query: {
          time,
          type: date_type,
          store_id: this.listQuery.store_id
        }
      })
    },
    toUnpaidReceiveView() {
      let time = ''
      let date_type = ''
      switch (this.dateTypeName) {
        case '按日统计':
          time = toDateString(this.currentDate)
          date_type = 'day'
          break
        case '按月统计':
          time = toDateStringMonth(this.currentDate)
          date_type = 'month'
          break
        case '按年统计':
          time = toDateStringYear(this.currentDate)
          date_type = 'year'
          break
      }
      this.$router.push({
        path: 'total-data-unpaid-receive',
        query: {
          time,
          type: date_type,
          store_id: this.listQuery.store_id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/styles/color";
  .filter {
    position: fixed;
    z-index: 10;
    top: 46px;
    left: 0;
    right: 0;
    background-color: #fff;
    box-shadow: 0px 1px 5px #efefef;
    .van-cell {
      height: 45px;
    }
  }
  .data {
    margin-top: 182px;
    // padding: 0 15px 15px 15px;
    .data-block {
      background-color: unset;
    }
    .data-block-head {
      margin-bottom: 10px;
    }
    .van-info {
      z-index: 2;
    }
    .van-grid-item__icon {
      font-size: 23px;
    }
    .van-grid-item__text {
      font-size: 13px;
      color: $grayTextColor;
    }
  }
  .amount {
    .van-cell__value {
        color: $amountRedColor;
      }
  }
  .blue {
    color: #24A8F4;
  }
  .yellow {
    color: #FFBF37;
  }
  .green {
    color: #4CC168;
  }

  .sub-title {
    color: #989898;
    font-size: 12px;
  }

  #mychart {
    height: 300px;
    background-color: #fff;
  }

</style>
