var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "数据总览",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "filter"
  }, [_c('van-cell', {
    attrs: {
      "center": "",
      "title": "门店",
      "value": _vm.currentStore,
      "is-link": ""
    },
    on: {
      "click": function click($event) {
        _vm.showActionSheet = true;
      }
    }
  }), _c('van-cell', {
    attrs: {
      "center": "",
      "title": "类型",
      "value": _vm.dateTypeName,
      "is-link": ""
    },
    on: {
      "click": function click($event) {
        _vm.showDateTypeActionSheet = true;
      }
    }
  }), _c('van-cell', {
    attrs: {
      "center": "",
      "title": "日期",
      "value": _vm.listQuery.date,
      "is-link": ""
    },
    on: {
      "click": function click($event) {
        _vm.showDatePicker = true;
      }
    }
  })], 1), _vm.totalData ? _c('div', {
    staticClass: "data"
  }, [_c('van-cell-group', {
    staticClass: "data-block",
    attrs: {
      "border": false,
      "title": "配送数据"
    }
  }, [_c('van-cell', {
    attrs: {
      "title": "订单数量",
      "value": "".concat(_vm.totalData.delivery_count, "\u5F20")
    }
  }), _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "订单金额",
      "value": "\uFFE5".concat(_vm.totalData.delivery_order_amount),
      "center": ""
    }
  }), _vm.totalData.delivery_red_packet_amount < 0 ? _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "扣减红包",
      "value": "\uFFE5".concat(_vm.totalData.delivery_red_packet_amount),
      "center": ""
    }
  }) : _vm._e(), _vm.totalData.delivery_coupon_amount < 0 ? _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "扣减优惠券",
      "value": "\uFFE5".concat(_vm.totalData.delivery_coupon_amount),
      "center": ""
    }
  }) : _vm._e(), _vm.totalData.delivery_after_amount < 0 ? _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "扣减退货",
      "value": "\uFFE5".concat(_vm.totalData.delivery_after_amount),
      "center": ""
    }
  }) : _vm._e(), _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "应收",
      "value": "\uFFE5".concat(_vm.totalData.delivery_sales_amount),
      "center": ""
    }
  }), _vm.totalData.delivery_unpaid_amount < 0 ? _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "扣减签单",
      "value": "\uFFE5".concat(_vm.totalData.delivery_unpaid_amount),
      "center": ""
    }
  }) : _vm._e(), _vm.totalData.delivery_decimal_amount < 0 ? _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "扣减尾数",
      "value": "\uFFE5".concat(_vm.totalData.delivery_decimal_amount),
      "center": ""
    }
  }) : _vm._e(), _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "定金",
      "value": "\uFFE5".concat(_vm.totalData.delivery_deposit_amount),
      "center": ""
    }
  }), _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "实收(含定金)",
      "value": "\uFFE5".concat(_vm.totalData.delivery_real_amount),
      "center": ""
    }
  })], 1), _vm.totalData.delivery_unpaid_amount < 0 ? _c('van-cell-group', {
    staticClass: "data-block",
    attrs: {
      "border": false,
      "title": "配送签单"
    }
  }, [_vm.totalData.delivery_unpaid_after_amount < 0 ? _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "签单退货",
      "value": "\uFFE5".concat(_vm.totalData.delivery_unpaid_after_amount),
      "center": ""
    }
  }) : _vm._e(), _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "实际签单",
      "value": "\uFFE5".concat(_vm.totalData.delivery_real_unpaid_amount),
      "center": ""
    }
  })], 1) : _vm._e(), _c('van-cell-group', {
    staticClass: "data-block",
    attrs: {
      "title": "门店数据"
    }
  }, [_c('van-cell', {
    attrs: {
      "title": "订单数量",
      "value": "".concat(_vm.totalData.store_count, "\u5F20")
    }
  }), _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "订单金额",
      "value": "\uFFE5".concat(_vm.totalData.store_order_amount),
      "center": ""
    }
  }), _vm.totalData.store_red_packet_amount < 0 ? _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "扣减红包",
      "value": "\uFFE5".concat(_vm.totalData.store_red_packet_amount),
      "center": ""
    }
  }) : _vm._e(), _vm.totalData.store_coupon_amount < 0 ? _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "扣减优惠券",
      "value": "\uFFE5".concat(_vm.totalData.store_coupon_amount),
      "center": ""
    }
  }) : _vm._e(), _vm.totalData.store_after_amount < 0 ? _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "扣减退货",
      "value": "\uFFE5".concat(_vm.totalData.store_after_amount),
      "center": ""
    }
  }) : _vm._e(), _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "应收",
      "value": "\uFFE5".concat(_vm.totalData.store_sales_amount),
      "center": ""
    }
  }), _vm.totalData.store_unpaid_amount < 0 ? _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "扣减签单",
      "value": "\uFFE5".concat(_vm.totalData.store_unpaid_amount),
      "center": ""
    }
  }) : _vm._e(), _vm.totalData.store_decimal_amount < 0 ? _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "扣减尾数",
      "value": "\uFFE5".concat(_vm.totalData.store_decimal_amount),
      "center": ""
    }
  }) : _vm._e(), _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "定金",
      "value": "\uFFE5".concat(_vm.totalData.store_deposit_amount),
      "center": ""
    }
  }), _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "实收(含定金)",
      "value": "\uFFE5".concat(_vm.totalData.store_real_amount),
      "center": ""
    }
  })], 1), _vm.totalData.store_unpaid_amount < 0 ? _c('van-cell-group', {
    staticClass: "data-block",
    attrs: {
      "border": false,
      "title": "门店签单"
    }
  }, [_vm.totalData.store_unpaid_after_amount < 0 ? _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "签单退货",
      "value": "\uFFE5".concat(_vm.totalData.store_unpaid_after_amount),
      "center": ""
    }
  }) : _vm._e(), _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "实际签单",
      "value": "\uFFE5".concat(_vm.totalData.store_real_unpaid_amount),
      "center": ""
    }
  })], 1) : _vm._e(), _c('van-cell-group', {
    attrs: {
      "title": "差异数据"
    }
  }, [_c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "退货差价",
      "value": "\uFFE5".concat(_vm.totalData.after_diff_amount),
      "center": ""
    }
  })], 1), _c('van-cell-group', {
    attrs: {
      "title": "门店营业"
    }
  }, [_c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "总订单金额",
      "value": "\uFFE5".concat(_vm.totalData.total_order_amount),
      "center": ""
    }
  }), _vm.totalData.total_red_packet_amount < 0 ? _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "总扣减红包",
      "value": "\uFFE5".concat(_vm.totalData.total_red_packet_amount),
      "center": ""
    }
  }) : _vm._e(), _vm.totalData.total_coupon_amount < 0 ? _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "总扣减优惠券",
      "value": "\uFFE5".concat(_vm.totalData.total_coupon_amount),
      "center": ""
    }
  }) : _vm._e(), _vm.totalData.total_after_amount < 0 ? _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "总扣减退货",
      "value": "\uFFE5".concat(_vm.totalData.total_after_amount),
      "center": ""
    }
  }) : _vm._e(), _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "总应收",
      "value": "\uFFE5".concat(_vm.totalData.total_sales_amount),
      "center": ""
    }
  }), _vm.totalData.total_unpaid_amount < 0 ? _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "总扣减签单",
      "value": "\uFFE5".concat(_vm.totalData.total_unpaid_amount),
      "center": ""
    }
  }) : _vm._e(), _vm.totalData.total_decimal_amount < 0 ? _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "总扣减尾数",
      "value": "\uFFE5".concat(_vm.totalData.total_decimal_amount),
      "center": ""
    }
  }) : _vm._e(), _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "总定金",
      "value": "\uFFE5".concat(_vm.totalData.total_deposit_amount),
      "center": ""
    }
  }), _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "总实收(含定金)",
      "value": "\uFFE5".concat(_vm.totalData.total_real_amount),
      "center": ""
    }
  }), _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "总成本",
      "value": "\uFFE5".concat(_vm.totalData.total_cost_amount),
      "center": ""
    }
  }), _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "总税费",
      "value": "\uFFE5".concat(_vm.totalData.total_tax_fee),
      "center": ""
    }
  }), _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "来货运费",
      "value": "\uFFE5".concat(_vm.totalData.total_trans_expenses),
      "center": ""
    }
  }), _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "value": "\uFFE5".concat(_vm.totalData.gross_profit),
      "center": ""
    }
  }, [_c('template', {
    slot: "title"
  }, [_c('span', [_vm._v("毛利")]), _c('span', {
    staticClass: "sub-title"
  }, [_vm._v("(总应收 - 总成本 - 总税费 - 来货运费)")])])], 2), _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "毛利率",
      "value": "".concat(_vm.totalData.gross_profit_rate, "%"),
      "center": ""
    }
  })], 1), _vm.totalData.total_unpaid_amount < 0 ? _c('van-cell-group', {
    attrs: {
      "title": "签单数据"
    }
  }, [_vm.totalData.total_unpaid_after_amount < 0 ? _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "总签单退货",
      "value": "\uFFE5".concat(_vm.totalData.total_unpaid_after_amount),
      "center": ""
    }
  }) : _vm._e(), _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "总实际签单",
      "value": "\uFFE5".concat(_vm.totalData.total_real_unpaid_amount),
      "center": ""
    }
  })], 1) : _vm._e(), _c('van-cell-group', {
    attrs: {
      "title": "其它收款"
    }
  }, [_c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "签单收款",
      "value": "\uFFE5".concat(_vm.totalData.unpaid_receive_amount),
      "center": "",
      "is-link": ""
    },
    on: {
      "click": _vm.toUnpaidReceiveView
    }
  }), _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "欠条收款",
      "value": "\uFFE5".concat(_vm.totalData.iou_receive_amount),
      "center": ""
    }
  })], 1), _c('van-cell-group', {
    attrs: {
      "title": "门店支出"
    }
  }, [_c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "来货入库",
      "value": "\uFFE5".concat(_vm.totalData.accept_order_amount),
      "is-link": "",
      "center": ""
    },
    on: {
      "click": _vm.toAcceptOrderView
    }
  }), _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "门店调货",
      "value": "\uFFE5".concat(_vm.totalData.trans_order_amount),
      "is-link": "",
      "center": ""
    },
    on: {
      "click": _vm.clickStoreTransfer
    }
  }), _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "费用报销",
      "value": "\uFFE5".concat(_vm.totalData.expense_amount),
      "center": ""
    }
  }), _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "日常开支",
      "value": "\uFFE5".concat(_vm.totalData.expense_daily_amount),
      "center": ""
    }
  })], 1), _c('van-cell-group', {
    attrs: {
      "title": "联盟商家（门店营业已包含此数据）"
    }
  }, [_c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "订单金额",
      "value": "\uFFE5".concat(_vm.totalData.business_amount),
      "center": ""
    }
  }), _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "退货金额",
      "value": "\uFFE5".concat(_vm.totalData.business_return_amount),
      "center": ""
    }
  }), _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "成本金额",
      "value": "\uFFE5".concat(_vm.totalData.business_cost_amount),
      "center": ""
    }
  }), _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "税费",
      "value": "\uFFE5".concat(_vm.totalData.business_tax_fee),
      "center": ""
    }
  }), _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "毛利",
      "value": "\uFFE5".concat(_vm.totalData.business_gross_profit),
      "center": ""
    }
  }), _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "毛利率",
      "value": "".concat(_vm.totalData.business_gross_profit_rate, "%"),
      "center": ""
    }
  })], 1), _c('van-cell-group', {
    attrs: {
      "title": "特惠购（门店营业已包含此数据）"
    }
  }, [_c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "订单金额",
      "value": "\uFFE5".concat(_vm.totalData.pss_amount),
      "center": ""
    }
  }), _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "成本金额",
      "value": "\uFFE5".concat(_vm.totalData.pss_cost_amount),
      "center": ""
    }
  }), _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "提成金额",
      "value": "\uFFE5".concat(_vm.totalData.pss_commission_amount),
      "center": ""
    }
  }), _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "毛利",
      "value": "\uFFE5".concat(_vm.totalData.pss_gross_profit),
      "center": ""
    }
  }), _c('van-cell', {
    staticClass: "amount",
    attrs: {
      "title": "毛利率",
      "value": "".concat(_vm.totalData.pss_gross_profit_rate, "%"),
      "center": ""
    }
  })], 1), _vm.totalData.chart_data ? _c('van-cell-group', {
    attrs: {
      "title": "销售额走势图"
    }
  }, [_c('div', {
    attrs: {
      "id": "mychart"
    }
  })]) : _vm._e()], 1) : _vm._e(), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.actions
    },
    on: {
      "select": _vm.selectStore
    },
    model: {
      value: _vm.showActionSheet,
      callback: function callback($$v) {
        _vm.showActionSheet = $$v;
      },
      expression: "showActionSheet"
    }
  }), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.dateTypeActions
    },
    on: {
      "select": _vm.selectDateType
    },
    model: {
      value: _vm.showDateTypeActionSheet,
      callback: function callback($$v) {
        _vm.showDateTypeActionSheet = $$v;
      },
      expression: "showDateTypeActionSheet"
    }
  }), _c('van-popup', {
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.showDatePicker,
      callback: function callback($$v) {
        _vm.showDatePicker = $$v;
      },
      expression: "showDatePicker"
    }
  }, [_c('van-datetime-picker', {
    attrs: {
      "type": "date",
      "title": "选择日期"
    },
    on: {
      "cancel": function cancel($event) {
        _vm.showDatePicker = false;
      },
      "confirm": _vm.selectDate
    },
    model: {
      value: _vm.currentDate,
      callback: function callback($$v) {
        _vm.currentDate = $$v;
      },
      expression: "currentDate"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }