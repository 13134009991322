import request from '@/utils/request'

// 获取销售总览
export function getSalesStatistics(params) {
  return request({
    method: 'get',
    url: 'statistics/sales',
    params
  })
}

// 获取数据总览
export function getTotalData(params) {
  return request({
    method: 'get',
    url: 'statistics/total',
    params
  })
}

// 获取来货入库数据
export function getTotalAcceptData(params) {
  return request({
    method: 'get',
    url: 'statistics/total-accept',
    params
  })
}

// 获取门店调货数据
export function getTotalTransData(params) {
  return request({
    method: 'get',
    url: 'statistics/total-trans',
    params
  })
}

// 员工获取来货入库数据
export function getEmployeeTotalAcceptData(params) {
  return request({
    method: 'get',
    url: 'statistics/employee-total-accept',
    params
  })
}

// 获取提成数据
export function getCommission(params) {
  return request({
    method: 'get',
    url: 'statistics/commission',
    params
  })
}

// 获取客户签单收款汇总
export function getUnpaidReceives(params) {
  return request({
    method: 'get',
    url: 'statistics/unpaid-receive',
    params
  })
}

// 获取客户签单收款明细
export function getUnpaidReceiveDetail(params) {
  return request({
    method: 'get',
    url: 'statistics/unpaid-receive/detail',
    params
  })
}
