import request from '@/utils/request'

export default {

  count() {
    return request({
      method: 'get',
      url: 'home'
    })
  }
}

export function getAlerts() {
  return request({
    method: 'get',
    url: 'alerts'
  })
}

export function cancelAlert() {
  return request({
    method: 'post',
    url: 'cancel-alert'
  })
}

export function getStores() {
  return request({
    method: 'get',
    url: 'stores'
  })
}
